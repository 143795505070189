<template>
  <div class="profile-table-comp">
    <div class="profile__name font-black heading-two bold">
      {{ user.first_name }} {{ user.last_name }}
    </div>
    <div class="profile__table">
      <v-container>
        <v-row :dense="true">
          <v-col md="2">
            <div class="table__column font-black font-regular bold v-application">Email:</div>
          </v-col>
          <v-col md="10" class="table__column font-black font-regular v-application">
            <div>{{ user.user_email }}</div>
          </v-col>
        </v-row>
        <v-row :dense="true">
          <v-col md="2">
            <div class="table__column font-black font-regular bold v-application">
              Hubland-Status:
            </div>
          </v-col>
          <v-col md="10" class="table__column font-black font-regular v-application">
            <div>{{ user.user_interests }}</div>
          </v-col>
        </v-row>
        <v-row :dense="true">
          <v-col md="2">
            <div class="table__column font-black font-regular bold v-application">Rolle:</div>
          </v-col>
          <v-col md="10" class="table__column font-black font-regular v-application">
            <div>{{ user.user_role }}</div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="profile__edit-btn">
      <EditProfile
        @ProfilePictureChange="$emit('ProfilePictureChange', $event)"
        @ProfileChange="$emit('ProfileChange')"
      />
    </div>
  </div>
</template>

<script>
import EditProfile from "@/components/EditProfile";

export default {
  name: "ProfileTable",
  components: { EditProfile },
  props: {
    user: { type: Object },
  },
};
</script>
