<template>
  <div class="birthday-input-comp">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          ref="date"
          v-model="date"
          :label="label"
          prepend-inner-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          outlined
          :rules="[rules.required]"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        :max="
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
        "
        min="1900-01-01"
        @change="save"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "BirthdayInput",
  props: {
    label: String,
    startDate: String,
  },
  data: () => ({
    activePicker: null,
    menu: false,
    date: null,
    rules: {
      required: (value) => !!value || "This field is required",
    },
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    date(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.date = this.startDate;
  },
  methods: {
    validate(bool) {
      return this.$refs.date.validate(bool);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
