<template>
  <v-dialog v-model="dialog" max-width="66%">
    <template #activator="{ on: { click }, attrs }">
      <Button
        :is-icon="true"
        icon="edit"
        label_text="Profil bearbeiten"
        v-bind="attrs"
        @click.native="click"
      />
    </template>

    <v-card ref="form" class="edit-profile-comp">
      <Button :is-cross="true" class="close" @click.native="dialog = false" />
      <v-container class="edit-container">
        <v-row>
          <v-card-title class="font-weight-black ma-auto mb-5"> Profil bearbeiten </v-card-title>
        </v-row>

        <div id="croppieModal" class="modal">
          <div class="modal-content modal-content-croppie">
            <span class="close" @click="closeDialog()"><v-icon>$x</v-icon></span>
            <p class="heading-three font-dark-grey modal-heading">Neues Profilbild hochladen</p>
            <vue-croppie
              ref="croppieRef"
              :enable-orientation="true"
              :enable-resize="false"
              :boundary="{ width: 350, height: 350 }"
              :viewport="{ width: 300, height: 300, type: 'circle' }"
            />
            <Button :is-primary="true" label_text="Zuschneiden" @click.native="crop" />
          </div>
        </div>
        <v-alert v-model="alert" type="error" dismissible>
          {{ errorMessages.toString() }}
        </v-alert>
        <v-row class="d-inline-flex avatar-preview">
          <v-avatar size="180" class="preview__wrapper shadow">
            <img v-if="croppieImage" :src="croppieImage" alt="Profilbild" />
            <img v-else-if="profilePicture" :src="profilePicture" alt="Profilbild" />
            <span v-else class="preview__no-avatar">{{ first_name.charAt(0) }}</span>
          </v-avatar>
          <v-container class="preview__submit-container">
            <input
              id="croppieInput"
              type="file"
              accept="image/*"
              style="display: none"
              @change="croppie"
            />

            <Button :is-icon="true" icon="edit" label_text="Ändern" @click.native="openInput" />
          </v-container>
        </v-row>
        <v-row class="d-flex; justify-center">
          <p id="error" class="edit__error">
            Es fehlen Angaben. Bitte ergänze die leeren Felder mit Informationen über dich.
          </p>
        </v-row>
        <v-row>
          <v-col class="edit__first-name">
            <v-text-field
              ref="first_name"
              v-model="first_name"
              class="Input"
              label="Vorname"
              placeholder="Vorname"
              required
              :rules="[rules.required]"
              outlined
            />
          </v-col>
          <v-col class="edit__last-name">
            <v-text-field
              ref="last_name"
              v-model="last_name"
              class="Input"
              label="Nachname"
              placeholder="Nachname"
              required
              :rules="[rules.required]"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <birthday-input
            ref="birthdate"
            v-model="birthdate"
            :start-date="birthdate"
            style="width: 100%; display: none"
            label="Geburtstag"
          />
        </v-row>
        <v-row>
          <v-text-field
            ref="interests"
            v-model="interests"
            label="Hubland-Status"
            placeholder="Ich wohne oder arbeite am Hubland"
            class="Input"
            outlined
          />
        </v-row>
        <v-row>
          <v-text-field
            ref="email"
            v-model="email"
            class="Input"
            label="Email"
            placeholder="Beispiel@email.de"
            required
            :rules="[rules.required, rules.validEmail]"
            outlined
          />
        </v-row>
        <v-row class="pb-4">
          <v-col align="center">
            <Button :is-delete="true" label_text="Profil löschen" />
          </v-col>
          <v-col align="center">
            <Button :is-primary="true" label_text="Profil speichern" @click.native="submit" />
          </v-col>
        </v-row>
        <v-divider class="mt-5 mb-5" />
        <v-row>
          <v-card-title class="font-weight-black ma-auto mb-1"> Passwort ändern </v-card-title>
        </v-row>
        <v-row class="d-flex; justify-center">
          <p id="errorPassword" class="edit__error">
            Um dein Passwort zu ändern, musst du beide Felder ausfüllen!
          </p>
        </v-row>
        <v-row>
          <password-input
            ref="password_old"
            label="Altes Passwort"
            hint="Das Passwort muss mindestens aus 8 Zeichen bestehen"
          />
        </v-row>
        <v-row>
          <password-input ref="password_new" label="Neues Passwort" />
        </v-row>

        <v-row class="pb-4">
          <v-col align="center">
            <Button
              :is-primary="true"
              label_text="Passwort ändern"
              @click.native="changePassword"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Button from "@/components/Button";
import PasswordInput from "@/components/inputs/passwordInput";
import Api, { baseURL } from "@/services/api";
import BirthdayInput from "@/components/inputs/BirthdayInput";
import authApi from "@/services/auth-api";
import AuthApi from "@/services/auth-api";
import { getLoggedInUser } from "@/services/loggedIn";

export default {
  name: "EditProfile",
  components: { PasswordInput, Button, BirthdayInput },
  data() {
    return {
      rules: {
        required: (value) => !!value || "This field is required",
        validEmail: (value) => {
          const emailPattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return emailPattern.test(value) || "Not a valid E-mail";
        },
      },
      alert: false,
      errorMessages: [],
      dialog: false,
      first_name: "",
      last_name: "",
      birthdate: "",
      email: "",
      interests: "",
      old_password: "",
      new_password: "",
      croppieImage: "",
      profilePicture: "",
    };
  },
  computed: {
    form() {
      return {
        first_name: this.first_name,
        last_name: this.last_name,
        birthdate: this.birthdate,
        email: this.email,
        interests: this.interests,
      };
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      Api.getUserProfile().then((user) => this.renderUser(user.data));
      Api.getProfilePicture().then((result) => {
        if (result.split(/[/\\]/gm)[0] === "profilePictures") {
          this.profilePicture = baseURL + "public/" + result;
        } else this.profilePicture = result;
      });
    },
    renderUser(userObj) {
      this.first_name = userObj.first_name;
      this.last_name = userObj.last_name;
      this.birthdate = userObj.birthdate;
      this.interests = userObj.interests;
      this.email = userObj.email;
    },
    openInput() {
      document.getElementById("croppieInput").click();
    },
    getValue() {
      return document.getElementById("textfield").value;
    },
    checkIfValid() {
      Object.keys(this.form).forEach((f) => {
        if (!this.$refs[f].validate(true)) {
          this.errorMessages.push(" Bitte füllen sie das Feld " + this.$refs[f].label + " aus");
          return true;
        }
      });
      return false;
    },
    submit() {
      this.alert = false;
      this.errorMessages = [];
      let HasErrors = false;
      HasErrors = this.checkIfValid();
      if (!HasErrors) {
        if (this.form.email !== JSON.parse(getLoggedInUser()).email) {
          authApi.checkEmailUnique({ email: this.form.email }).then((res) => {
            if (res.data.unique === false) {
              this.errorMessages.push(
                "Die E-mail Adresse wird bereits von einem anderen Account genutzt!"
              );
              this.alert = true;
            }
          });
        }
      } else this.alert = true;
      if (!this.alert) {
        if (this.croppieImage) {
          Api.changeProfilePicture({ data: this.croppieImage }).then(() => {
            this.$emit("ProfilePictureChange", this.croppieImage);
          });
        }
        Api.changeProfileData(this.form).then((res) => {
          this.$emit("ProfileChange", res);
          this.dialog = false;
        });
      }
    },
    changePassword() {
      this.errorMessages = [];
      this.alert = false;
      let passwordOld = this.$refs.password_old.getValue();
      let passwordNew = this.$refs.password_new.getValue();
      if (!this.$refs.password_old.validate(true) || !this.$refs.password_new.validate(true)) {
        this.errorMessages.push("Bitte Füllen sie die Felder aus");
        this.alert = true;
      } else if (passwordNew.length < 8) {
        this.errorMessages.push("Das neue Passwort muss mindestens aus 8 Zeichen bestehen!");
        this.alert = true;
      } else {
        AuthApi.changePassword(passwordOld, passwordNew).then((res) => {
          if (res.data) {
            this.$router.push("/login");
          }
        });
      }
    },
    croppie(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var modal = document.getElementById("croppieModal");
      modal.style.display = "block";

      let reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result,
        });
      };

      reader.readAsDataURL(files[0]);
    },
    crop() {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      let options = {
        type: "base64",
        size: { width: 600, height: 600 },
        format: "png",
      };
      this.$refs.croppieRef.result(options, (output) => {
        this.croppieImage = output;
      });

      this.$refs.croppieRef.refresh();
      var modal = document.getElementById("croppieModal");
      modal.style.display = "none";
    },
    closeDialog() {
      var modal = document.getElementById("croppieModal");
      modal.style.display = "none";
    },
  },
};
</script>
