<template>
  <div class="profile-view">
    <div>
      <div class="profile__content shadow">
        <v-container class="profile-content__user-info">
          <v-row no-gutters>
            <v-col class="user-info__avatar" cols="12" md="2">
              <v-avatar v-if="!user.profilePicture" size="210">
                <span class="avatar__default">{{ user.first_name.charAt(0) }}</span>
              </v-avatar>
              <v-avatar v-else size="210">
                <img :src="user.profilePicture" :alt="'Profilbild von ' + user.first_name" />
              </v-avatar>
            </v-col>
            <v-col class="user-info__details" md="10">
              <ProfileTable
                :user="user"
                @ProfilePictureChange="changeProfilePicture"
                @ProfileChange="getUser"
              />
            </v-col>
          </v-row>

          <v-row class="user-info__bottom-tabs">
            <button
              id="myPostcards"
              class="bottom-tabs__button font-black font-medium bold"
              @click="switchMyPostCards"
            >
              Meine Postkarten
            </button>
            <button
              id="savedPostcards"
              class="bottom-tabs__button font-middle-grey font-medium bold"
              @click="switchSavedPostCards"
            >
              Gespeicherte Postkarten
            </button>
          </v-row>
        </v-container>
      </div>

      <div class="postcard-content">
        <Feed
          class="postcard-content__feed"
          :postcards="isOwnPostcards ? ownPostcards : savedPostcards"
          :isEndReached="isEndReached"
        />
      </div>
    </div>
    <Button
      :is-icon="true"
      icon="writePostcard"
      label_text="Postkarte schreiben"
      class="writePostcard"
      @click.native="$router.push('/createPostcard'), sendMatomoEvent()"
    />
  </div>
</template>

<script>
import ProfileTable from "@/components/ProfileTable";
import Feed from "@/components/Feed";
import Button from "@/components/Button";
import Api, { baseURL } from "@/services/api";

// import {getLoggedInUser} from "@/services/loggedIn";

export default {
  name: "Profile",
  components: { ProfileTable, Feed, Button },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        user_age: 0,
        user_email: "",
        user_interests: "",
        user_role: "",
        profilePicture: null,
      },
      isOwnPostcards: true,
      ownPostcards: [],
      savedPostcards: [],
      isEndReached: true,
    };
  },
  beforeMount() {
    this.getUser();
    this.getProfilePic();
    this.getInitialPosts();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    sendMatomoEvent() {
      window._paq.push([
        "trackEvent",
        "CreatePostcard on hubbel",
        "Click 'Write Postcard' Button",
        "'Write Postcard' clicked.",
      ]);
    },
    changeProfilePicture(pic) {
      this.user.profilePicture = pic;
      this.$emit("ProfilePictureChange", pic);
    },
    handleScroll() {
      if (
        Math.ceil(
          Math.max(window.scrollY, document.documentElement.scrollTop, document.body.scrollTop)
        ) +
          Math.ceil(window.innerHeight) >=
        Math.floor(document.documentElement.offsetHeight)
      ) {
        if (this.isOwnPostcards) {
          if (this.ownPostcards.length !== 0) {
            Api.getNextPostsFromUser(3, this.ownPostcards.length).then((res) => {
              res.data.forEach((postObj) => this.renderPost(postObj, true));
              this.isEndReached = res.data.length <= 0;
            });
          }
        } else if (!this.isOwnPostcards) {
          if (this.savedPostcards.length !== 0) {
            Api.getNextSavedPosts(3, this.savedPostcards.length).then((res) => {
              res.data.forEach((postObj) => this.renderPost(postObj.post, false));
              this.isEndReached = res.data.length <= 0;
            });
          }
        }
      }
    },
    switchMyPostCards() {
      this.isOwnPostcards = true;
      this.toggleBtn();
    },
    switchSavedPostCards() {
      this.isOwnPostcards = false;
      this.toggleBtn();
    },
    toggleBtn() {
      //TODO toggle an "active" class that has style in scss
      document.getElementById("myPostcards").style.color = this.isOwnPostcards
        ? "#000000"
        : "#9F9F9F";
      document.getElementById("savedPostcards").style.color = !this.isOwnPostcards
        ? "#000000"
        : "#9F9F9F";
    },
    getUser() {
      Api.getUserProfile().then((user) => this.renderUser(user.data));
    },
    getProfilePic() {
      Api.getProfilePicture().then((result) => {
        if (result.split(/[/\\]/gm)[0] === "profilePictures") {
          this.user.profilePicture = baseURL + "public/" + result;
        } else this.user.profilePicture = result;
      });
    },
    renderUser(userObj) {
      this.user.first_name = userObj.first_name;
      this.user.last_name = userObj.last_name;
      this.user.user_age = this.convertAge(userObj.birthdate);
      this.user.user_role = userObj.level_of_user;
      this.user.user_interests = userObj.interests;
      this.user.user_email = userObj.email;
    },

    convertAge(birthdate) {
      const now = new Date();
      const bd = new Date(birthdate.replace(/\s/, "T") + "Z");
      let age = now.getFullYear() - bd.getFullYear();
      if (
        now.getMonth() < bd.getMonth() ||
        (now.getMonth() === bd.getMonth() && now.getDay() < bd.getDay())
      ) {
        age--;
      }
      return age;
    },

    getInitialPosts() {
      Api.getNextPostsFromUser(5, 0).then((res) =>
        res.data.forEach((postObj) => this.renderPost(postObj, true))
      );
      Api.getNextSavedPosts(5, 0).then((res) => {
        res.data.forEach((postObj) => {
          this.renderPost(postObj.post, false);
        });
      });
    },
    renderPost(postObj, isOwn) {
      if (isOwn) {
        if (this.ownPostcards.some((e) => e.id === postObj.post_id)) {
          return;
        }
      } else {
        if (this.savedPostcards.some((e) => e.id === postObj.post_id)) {
          return;
        }
      }

      function getSender() {
        if (postObj.sender_name) {
          return postObj.sender_name;
        } else if (postObj.user.team && postObj.user.team.length > 0) {
          return postObj.user.team;
        } else if (postObj.user.last_name.length <= 0) {
          return postObj.user.first_name;
        } else {
          return postObj.user.first_name + " " + Array.from(postObj.user.last_name)[0] + ".";
        }
        //TODO maybe keep full name here for moderators
      }

      let post = {
        id: postObj.post_id,
        readOnly: true,
        approved: postObj.approved,
        hidden: postObj.hidden,
        information: postObj.information,
        category: postObj.category,
        sender_verified: postObj.user.verified,
        sender_team: postObj.user.team,
        sender: getSender(),
        subject: postObj.topic.name,
        text: postObj.text,
        date: postObj.createdAt.split("T")[0] + " " + postObj.createdAt.split(/[T.]/)[1],
        attachments: postObj.Attachments,
        isSaved: isOwn ? postObj.isSaved : true,
        sentFrom: postObj.sentFrom,
        show: false,
      };
      isOwn ? this.ownPostcards.push(post) : this.savedPostcards.push(post);
    },
    /*
  beforeCreate() {
    if(!getLoggedInUser()){
      this.$router.push("/login");
    }
  }
   */
  },
};
</script>
